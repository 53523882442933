<script setup>
    import { initNavBar } from 'o365.controls.NavBar.js';
    
    const props = defineProps({
        contextWhereClause: {
            type: String
        },
        hideOrgUnitsTree: { 
            type: Boolean,
            default: false,
        },
        operationMode: {
            type: Boolean,
            default: false
        },
        domainMode: {
            type: Boolean,
            default: false
        },
        title: {
            type: String
        }
    });

    initNavBar({ 
        operationMode: props.operationMode, 
        contextWhereClause: props.contextWhereClause, 
        hideOrgUnitsTree: props.hideOrgUnitsTree,
        domainMode: props.domainMode,
        title:props.title
    }); 
</script>

<template>
    <!-- ONavbar: o365.controls.NavBar.js -->
</template>